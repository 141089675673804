.subscribe.display {
  z-index: 2;
  background: #4f8;
  border-radius: 2rem 0;
  padding: 1.5rem 0;
  position: absolute;
  top: 2rem;
  right: 0;
}

.subscribe.display > h2 {
  color: #000;
}

.subscribe.display > span {
  padding: 0 1.25rem;
}

.subscribe.display #mailTeam {
  background: #fff;
  padding: 0 1.25rem;
  font-size: 1.25em;
  font-weight: bold;
}

.fab summary {
  height: 100%;
  z-index: 11;
  align-items: center;
  display: flex;
  list-style: none !important;
}

.fab summary > span.title {
  width: 100%;
  text-align: center;
  display: block;
}

.fab > details > summary {
  list-style-type: none !important;
}

.P > .fab {
  top: calc(var(--here-height)  - 6rem);
  right: calc(0px - var(--nearWest-width)  - 3rem);
  width: 4rem;
  height: 4rem;
  color: #00f;
  z-index: 11;
  background: #4f8;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  transition: right .5s, top .5s, transform .2s;
  display: flex;
  position: absolute;
}

.P > .fab:hover {
  transform: scale(1.1);
}

.P > .fab span.title {
  letter-spacing: -.5px;
  font-family: subaruheavy;
  font-size: .875em;
  font-weight: 800;
  line-height: 1ex;
}

.P > .fab.soon span.title {
  text-align: center;
  color: #000;
  line-height: .8rem;
}

.P > .fab.subscribe {
  right: -2rem;
}

.P > .fab.register.inactive {
  background: silver;
}

/*# sourceMappingURL=index.6b1b0cde.css.map */
