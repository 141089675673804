//------

.subscribe.display {
    background: #4F8;
    padding: 1.5rem 0;
    position: absolute;
    top: 2rem;
    z-index: 2;
    border-radius: 2rem 0;
    right: 0;

    >h2 {
        color: black;
    }

    >span {
        padding: 0 1.25rem;
    }

    #mailTeam {
        padding: 0 1.25rem;
        background: white;
        font-weight: bold;
        font-size: 1.25em;
    }
}

.fab summary {
    list-style: none !important;
    height: 100%;
    display: flex;
    align-items: center;

    z-index: 11;

    >span.title {
        display: block;
        width: 100%;
        text-align: center;
    }
}


.fab {
    >details {
        >summary {
            list-style-type: none !important;
        }
    }
}

.P>.fab {
    position: absolute;
    top: calc(var(--here-height) - 6rem);
    right: calc(0px - var(--nearWest-width) - 3rem);
    transition: right 0.5s, top 0.5s, transform 0.2s;
    display: block;
    background: #4f8;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    color: blue;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        transform: scale(1.1);
    }

    span.title {
        line-height: 1ex;
        font-family: "subaruheavy";
        font-weight: 800;
        letter-spacing: -0.5px;
        font-size: calc(7em / 8);
    }
    &.soon span.title {
        text-align: center;
        line-height: .8rem;
        color: black;
    }
}

.P>.fab.subscribe {
    right: -2rem;
}

.P>.fab.register.inactive {
    background: silver;
  }